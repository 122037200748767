<template>
  <v-tooltip top :disabled="!tooltipText">
    <template v-slot:activator="{ on, attrs }">
      <span
        ref="button"
        class="button"
        v-bind="attrs"
        v-on="on"
        :class="[
          `button--${color}`,
          {
            'button--dense': dense,
            'button--disabled': loading || disabled,
            'button--square': square,
            'button--no-margin': noMargin,
          }
        ]"
        @click="!loading && !disabled && $emit('click')"
        @mousemove="onMousemove"
      >
        <span class="d-flex justify-center align-center">
          <span v-if="!loading" class="text d-flex align-center"><slot/></span>
          <Loading v-if="loading" :width="40" infinite class="loading"/>
        </span>
        <span v-if="!loading" class="hover" :style="hoverStyle" />
      </span>
    </template>
    <span>{{tooltipText}}</span>
  </v-tooltip>
</template>

<script>
export default ({
  components: {
    Loading: () => import('@/components/elements/Loading')
  },
  props: {
    loading: {type: Boolean, default: false },
    disabled: {type: Boolean, default: false },
    dense: {type: Boolean, default: false },
    outline: {type: Boolean, default: false },
    square: {type: Boolean, default: false },
    tooltipText: {type: String, default: '' },
    color: {
      type: String,
      default: 'primary',
      validator: (s) => [
        'primary',
        'secondary',
        'tertiary',
        'clear',
        'success',
        'error',
      ].includes(s),
    },
    noMargin: { type: Boolean, default: false },
  },
  data(){
    return {
      translateX: 0,
      translateY: 0,
    }
  },
  computed: {
    hoverStyle() {
      return { transform: `translate(${this.translateX}px, ${this.translateY}px)` }
    }
  },
  methods: {
    onMousemove(mouseEvent) {
      const { button } = this.$refs
      const { clientX, clientY } = mouseEvent
      if (button && clientX && clientY) {
        this.translateX = Math.floor(clientX - button.getBoundingClientRect().left - (button.offsetWidth / 2))
        this.translateY = Math.floor(clientY - button.getBoundingClientRect().top - (button.offsetHeight / 2))
      }
    }
  },
})
</script>

<style lang="scss" scoped>
.button {
  background: var(--button-background);
  border-radius: 999px;
  color: var(--button-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px !important;
  font-weight: 400;
  min-height: 42px;
  min-width: 78px;
  overflow: hidden;
  outline: 1px solid var(--button-outline-color, none);
  padding: 9px 28px;
  position: relative;
  text-transform: none;
  transform: translateZ(0); // safari overflow fix

  &--dense {
    padding: 3px 24px;
    min-height: 30px;
  }

  &--disabled {
    cursor: default;
    cursor: not-allowed;
    opacity: 0.6;

    .hover {
      display: none;
    }
  }

  &--square {
    min-width: 42px;
    width: 42px;
    padding: 0px;
  }

  .loading {
    position: relative;
  }

  .hover {
    background: radial-gradient(circle, rgba(var(--color-white), 0.6) 0%, var(--button-background) 100%);
    height: 200%;
    left: -50%;
    opacity: 0;
    position: absolute;
    top: -50%;
    transition: 0.5s opacity $ease, 0.1s transform $ease, 0.3s background $ease;
    width: 200%;
  }
  &:active .hover {
    background: radial-gradient(circle, rgba(var(--color-white), 0.8) 0%, var(--button-hover-to) 100%);
  }

  .text {
    display: flex;
    gap: 0.25em;
    color: var(--label-color, var(--button-color)) !important;
    z-index: 1;
  }

  .loading :deep(.cls-logo) {
    stroke: var(--label-color, var(--button-color)) !important;
  }

  :deep(i.v-icon) {
    color: var(--button-color) !important;
    font-size: 18px !important;
  }

  &:focus,
  &:hover {
    text-decoration: none;
    .hover {
      opacity: 1;
    }
  }
}

@media (max-width: 960px){
  .button {
    margin-left: auto;
    margin-right: auto;
    &--no-margin {
      margin: 0 !important;
    }
  }
}
</style>